import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import MainLayout from '../../components/MainLayout'
import { Hero, HowItWorks, Features } from '../../components/Products/Voice'
import Demo from '../../components/Demo'
// import { EGAnalytics, analyticEvents } from '../services/analytics'

const scrollHandler = () => {
  if (!window.contentViewed) {
    if ((window.innerHeight + document.documentElement.scrollTop) >= document.body.scrollHeight) {
      // EGAnalytics.track(analyticEvents.VOICE_PAGE_CONTENT_VIEWED)
      window.contentViewed = true
    }
  }
}

function ProductPage(props) {
  const { data } = props.pageContext

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.contentViewed = false
      window.onscroll = scrollHandler
    }
    // EGAnalytics.track(analyticEvents.VOICE_PAGE_VIEWED)
    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [])

  return (
    <MainLayout data={ data }>
      <Hero data={ data.hero } />
      <HowItWorks data={ data.howItWorks } />
      <Features features={ data.productFeatures } />
      <Demo data={data.getDemoCta}/>
    </MainLayout>
  )
}

ProductPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

export default ProductPage
